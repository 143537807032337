<template>
  <v-container fluid fill-height id="container" style="background-color:#f5f7fa;" ref="loginView">
    <div id="topBar"></div>
    <v-layout align-center justify-center>
      <v-flex xs11 sm8 md6 lg3 xl3>
        <ValidationObserver ref="observer" v-slot="{ passes }">
          <v-form @submit.prevent="passes(loginSubmit)" class="login-from login-box form">
            <v-row id="titleArea" no-gutters justify="center">
              <v-img :src="images.fullLogo" id="logoTitle"></v-img>
              <div id="firstDivider"></div>
            </v-row>
            <v-card-text id="formArea">
              <div class="fieldArea">
                <ValidationProvider name="user.userId" rules="required" v-slot="{ errors }" tag="div">
                  <g-text-field v-model="userId" outlined :placeholder="$t('user.userId')" prepend-inner-icon="mdi-id-icon" id="userId" name="userId" autocomplete="off" :tabindex="1" clearable :error-messages="errors" ref="userId" @keyup.enter="passes(loginSubmit)"/>
                </ValidationProvider>
              </div>
              <div class="fieldArea">
                <ValidationProvider name="user.userPass" rules="required" v-slot="{ errors }">
                  <g-text-field v-model="userPassword" outlined :placeholder="$t('user.userPass')" prepend-inner-icon="mdi-pw-icon" :type="userPassShow ? 'text' : 'password'" id="userPass" name="userPass" :tabindex="2" :append-icon="userPassShow ? 'visibility' : 'visibility_off'" @click:append="userPassShow = !userPassShow" autocomplete="new-password" ref="userPass" :error-messages="errors" @keyup.enter="passes(loginSubmit)"/>
                </ValidationProvider>
              </div>
              <div class="seper"></div>
              <div class="buttonArea">
                <v-btn plain raised block type="submit" width="325" height="42" color="white" elevation="0" :tabindex="3">
                  로그인
                  <v-icon right>mdi-arrow-icons</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-form>
        </ValidationObserver>
      </v-flex>
    </v-layout>
    <div id="downBar">
      <div id="downTitle">{{'COPYRIGHT© 2024 GYEYOUNG I&C Co. All right reserved.'}}</div>
    </div>
  </v-container>
</template>

<script>
import { find, sortBy } from 'lodash'
import GTextField from '@/components/form/GTextField.vue'

export default {
  name: 'loginForm',
  components: {
    GTextField
  },
  data () {
    return {
      images: {
        fullLogo: require('@/assets/images/logo/logo_duru_full.svg'),
        loginBtn: require('@/assets/images/login/rectangle.png'),
        idIcons: require('@/assets/images/login/icon-id.png'),
        arrowIcons: require('@/assets/images/login/icon-arrow-r.png')
      },
      spotSeq: null,
      userId: '',
      userPassword: '',
      userPassShow: false
    }
  },
  watch: {
    //
  },
  mounted () {
    sessionStorage.removeItem('botw_access_info')

    this.$store.state.login.userSeq = null
    this.$store.state.login.userName = ''
    this.$store.state.login.userId = ''
    this.$store.state.login.spotSeq = null
    this.$store.state.login.spotName = null
    this.$store.state.login.loginKey = ''
    this.$store.state.login.encKey = ''
    this.$store.state.login.menuList = []
    this.$store.state.login.consignCompName = ''
    this.$store.state.login.userGroupCode = ''
    this.$store.state.login.userSpotList = []
  },
  created () {
    // window.removeEventListener('unload', this.removeCookies)
    // window.addEventListener('unload', this.removeCookies)
  },
  methods: {
    loginSubmit: function () {
      console.log('loginSubmit : ', this.userId, this.userPassword)
      const CryptoJS = require('crypto-js')
      const Base64 = require('base-64')
      // 한글로 입력 시 콘솔에러 발생 조치_24.08.26_(unescape(encodeURIComponent(this.userId)))
      const encId = Base64.encode(unescape(encodeURIComponent(this.userId)))
      const cryptOptions = {}
      const iv = window.getHex(this.$store.state.basicEncIv)
      cryptOptions.iv = CryptoJS.enc.Hex.parse(iv)
      const key = window.getHex(this.$store.state.basicEncKey)
      // const encPw = CryptoJS.SHA512(this.userPassword).toString()
      const encPass = CryptoJS.AES.encrypt(this.userPassword, CryptoJS.enc.Hex.parse(key), cryptOptions).toString()

      // const encPw = CryptoJS.SHA512(this.userPassword).toString()
      const data = {
        userId: encId,
        userPass: encPass
      }
      if (data.userId !== '' && data.userPass !== '') {
        this.$store.dispatch('login', [data, this.loginSubmitCallback])
      }
    },
    loginSubmitCallback: function (response) {
      console.log('loginSubmitCallback :', JSON.parse(JSON.stringify(response)))
      if (response !== '' && response !== undefined && response.data !== '' && response.data !== undefined) {
        const data = response === undefined ? '' : response.data
        if (data !== '' && data !== undefined) {
          console.log('loginSubmitCallback : ', response.data)
          const success = response.data.success
          if (success) {
            const userGroupCode = response.data.userGroupCode
            const userSeq = response.data.userSeq
            const userName = response.data.userName
            const userId = this.userId
            const pwdChangeFlag = response.data.pwdChangeFlag
            const loginKey = response.data.loginKey
            const encKey = response.data.encKey
            const menuList = response.data.menuList
            const groupPermissionList = response.data.groupPermissionList
            const userTypeGroup = response.data.userTypeGroup
            const userGroupName = response.data.userGroupName
            // const orderContractCompSeq = response.data.orderContractCompSeq
            const consignCompName = response.data.consignCompName
            const resUserSpotList = response.data.userSpotList
            const homeNetSpot = sortBy(response.data.userHnSpotList, 'spotName')
            const icfacilitiesSpot = sortBy(response.data.userSpotList, 'spotName')
            const userSpotGroupList = []
            if (response.data.userHnSpotList !== undefined) {
              userSpotGroupList.push(homeNetSpot)
              userSpotGroupList.push(icfacilitiesSpot)
            } else if (response.data.userHnSpotList === undefined) userSpotGroupList.push(resUserSpotList)
            // [vuexTest] 1: 시스템 관리자, 2: 현장 관리자, 3: 원격 점검자, 'CD00003': 방문 점검자, '4': 계약 관리자
            const userGroupTitle = userGroupCode === 1 ? 'Admin' : userGroupCode === 5 ? 'Remote' : userGroupCode === 2 ? 'Spot' : userGroupCode === 4 ? 'Contract' : ''
            const componentTitle = userGroupCode === 1 || userGroupCode === 5 ? 'Remote' : userGroupCode === 2 ? 'Spot' : userGroupCode === 4 ? 'Contract' : ''
            // const componentGubun = response.data.componentGubun
            const mainComponentPath = userGroupCode === 1 ? '' : userGroupCode === 2 ? 'homeNet/spot/' : userGroupCode === 5 ? 'homeNet/remote/' : userGroupCode === 4 ? 'homeNet/contract/' : userGroupCode === 3 ? 'icfacilities/' : ''
            const menuListGroup = []
            let menusList = []
            let groupPermission1List = []
            let userSpotList = []

            // dataFlag 반영 추가(with. 메뉴관리)_24.08.07
            for (let i = 0; i < menuList.length; i++) {
              const menu = menuList[i]
              const menuLevel = menu.level
              if (menuLevel === 1 && menu.menuSeq !== 1000000) { // 메인 메뉴목록으로 표출 안되게
                const groupPermission = find(groupPermissionList, { menuSeq: menu.menuSeq }).groupPermission
                if (groupPermission !== undefined) menu.groupPermission = groupPermission
                menu.active = false
                groupPermission1List.push(menu)
                if (menu.dataFlag !== null && menu.dataFlag !== undefined && menu.dataFlag) menusList.push(menu)
                if (menu.dataFlag === undefined || menu.dataFlag === null) menusList.push(menu)
              }
            }
            for (let i = 0; i < menusList.length; i++) {
              const menu = menusList[i]
              const menuSeq1 = menu.menuSeq
              const menu2List = []
              for (let j = 0; j < menuList.length; j++) {
                const menu2 = menuList[j]
                if (menu2.level === 2) {
                  menu2.active = false
                  const menuUpper2 = menu2.upperMenuSeq
                  if (menuSeq1 === menuUpper2) {
                    const groupPermission = find(groupPermissionList, { menuSeq: menu2.menuSeq }).groupPermission
                    if (groupPermission !== undefined) menu2.groupPermission = groupPermission
                    groupPermission1List.push(menu2)
                    if (menu2.dataFlag !== null && menu2.dataFlag !== undefined && menu2.dataFlag) menu2List.push(menu2)
                    if (menu2.dataFlag === undefined || menu2.dataFlag === null) menu2List.push(menu2)
                  } else {
                    if (menu2List.length > 0) {
                      menu.items = menu2List
                      break
                    }
                  }
                }
              }
              menu.items = menu2List
            }
            for (let i = 0; i < menusList.length; i++) {
              const menu = menusList[i]
              const items = menu.items
              for (let j = 0; j < items.length; j++) {
                const menu2 = items[j]
                const menuSeq2 = menu2.menuSeq
                const menu3List = []
                for (let k = 0; k < menuList.length; k++) {
                  const menu3 = menuList[k]
                  menu3.active = false
                  if (menu3.level === 3 && menu3.menuSeq !== 6040600) { // 운영자설정 > 시스템설정 > 시스템설정 메뉴 표출 X
                    const groupPermission = find(groupPermissionList, { menuSeq: menu3.menuSeq }).groupPermission
                    if (groupPermission !== undefined) menu3.groupPermission = groupPermission
                    const menuUpper2 = menu3.upperMenuSeq
                    if (menuSeq2 === menuUpper2) {
                      groupPermission1List.push(menu3)
                      if (menu3.dataFlag !== null && menu3.dataFlag !== undefined && menu3.dataFlag) menu3List.push(menu3)
                      if (menu3.dataFlag === undefined || menu3.dataFlag === null) menu3List.push(menu3)
                    }
                  }
                }
                if (menu3List.length > 0) {
                  menu2.items = menu3List
                }
              }
            }
            console.log('login-menusList : ', userGroupCode, JSON.parse(JSON.stringify(menusList)))

            // 1: 시스템 관리자_구분(홈네트워크/정보통신) 별 메뉴
            if (userGroupCode === 1) {
              const homeNetMenu = []
              const icfacilitiesMenu = []
              const homeNetSettingMenu = []
              const icfacilitiesSettingMenu = []

              const homeNetItem = {
                spotSeq: 0,
                spotName: this.$t('texts.allSelect'),
                userTypeCode: 1,
                userTypeName: 'homeNet'
              }
              userSpotGroupList[0].unshift(homeNetItem)
              userSpotList = userSpotGroupList[0]

              // const icfacilitiesItem = {
              //   spotSeq: 0,
              //   spotName: this.$t('texts.selectItem'),
              //   userTypeCode: 1,
              //   userTypeName: 'icfacilities'
              // }
              // userSpotGroupList[1].unshift(icfacilitiesItem)
              // userSpotList = userSpotGroupList[1]

              const checkCount = (menu) => {
                if (menu.items !== undefined) {
                  if (menu.level === 1) {
                    // 2000000/3000000/4000000/5000000: 사용자 홈네트워크 메뉴
                    if (menu.menuSeq === 2000000 || menu.menuSeq === 3000000 || menu.menuSeq === 4000000 || menu.menuSeq === 5000000) icfacilitiesMenu.push(menu)
                    // 7000000: 사용자 홈네트워크 메뉴
                    if (menu.menuSeq === 7000000) homeNetMenu.push(menu)
                    // 6000000: 운영자 설정
                    if (menu.menuSeq === 6000000) {
                      const preMenu = JSON.parse(JSON.stringify(menu))
                      preMenu.items = []
                      homeNetSettingMenu.push(JSON.parse(JSON.stringify(preMenu)))
                      icfacilitiesSettingMenu.push(JSON.parse(JSON.stringify(preMenu)))
                      menu.items.forEach((menu, index) => {
                        checkCount(menu)
                      })
                    }
                  }
                  if (menu.level === 2) {
                    if (menu.menuSeq === 6060000) {
                      icfacilitiesSettingMenu[0].items.push(menu)
                    }
                    if (menu.menuSeq === 6050000) {
                      homeNetSettingMenu[0].items.push(menu)
                    }
                    if (menu.menuSeq === 6030000 || menu.menuSeq === 6040000) {
                      icfacilitiesSettingMenu[0].items.push(menu)
                      homeNetSettingMenu[0].items.push(menu)
                    }
                  }
                }
              }

              menusList.forEach((menu, index) => {
                checkCount(menu)
              })
              homeNetMenu.push(...homeNetSettingMenu)
              icfacilitiesMenu.push(...icfacilitiesSettingMenu)
              menuListGroup.push(homeNetMenu)
              menuListGroup.push(icfacilitiesMenu)
              menusList = menuListGroup[0]
            } else if (userGroupCode === 2) userSpotList = userSpotGroupList[0]

            groupPermission1List = JSON.parse(JSON.stringify(groupPermission1List))
            groupPermission1List.forEach((el, index) => {
              if (el.items !== undefined && el.items.length > 0) delete el.items
            })
            console.log('groupPermission1List : ', groupPermission1List)

            this.$store.state.login.mainComponentPath = mainComponentPath
            this.$store.state.login.userSeq = userSeq
            this.$store.state.login.userName = userName
            this.$store.state.login.userId = userId
            this.$store.state.login.pwdChangeFlag = pwdChangeFlag
            this.$store.state.login.loginKey = loginKey
            this.$store.state.login.encKey = encKey
            this.$store.state.login.menuListGroup = JSON.parse(JSON.stringify(menuListGroup))
            this.$store.state.login.menuList = JSON.parse(JSON.stringify(menusList))
            this.$store.state.login.userGroupCode = userGroupCode
            this.$store.state.login.userTypeGroup = userTypeGroup
            this.$store.state.login.userGroupName = userGroupName
            this.$store.state.login.userSpotGroupList = userSpotGroupList
            this.$store.state.login.userGroupTitle = userGroupTitle
            this.$store.state.login.componentTitle = componentTitle
            // this.$store.state.login.componentGubun = componentGubun
            // // this.$store.state.login.orderContractCompSeq = orderContractCompSeq
            this.$store.state.login.consignCompName = consignCompName

            // 로그인 직후는 첫번째 현정선택
            if (userSpotList.length > 0) {
              this.$store.state.login.spotSeq = userSpotList[0].spotSeq
              this.$store.state.login.spotName = userSpotList[0].spotName
            }

            sessionStorage.setItem('botw_access_info', JSON.stringify({
              mainComponentPath: mainComponentPath,
              userSeq: this.$store.state.login.spotSeq,
              userName: userName,
              userId: userId,
              pwdChangeFlag: pwdChangeFlag,
              loginKey: loginKey,
              encKey: encKey,
              menuList: menusList,
              menuListGroup: menuListGroup,
              userGroupCode: userGroupCode,
              userGroupTitle: userGroupTitle,
              componentTitle: componentTitle,
              // componentGubun: componentGubun,
              userTypeGroup: userTypeGroup,
              spotSeq: this.$store.state.login.spotSeq,
              spotName: this.$store.state.login.spotName,
              userGroupName: userGroupName,
              groupPermissionList: groupPermission1List,
              userSpotGroupList: userSpotGroupList,
              consignCompName: consignCompName
            }))

            let mainPath = ''
            if (userGroupCode === 7) mainPath = '/botw/main'
            if (userGroupCode === 2) mainPath = '/botw/main/homeNet/spot'
            if (userGroupCode === 5) mainPath = '/botw/main/homeNet/remote'
            if (userGroupCode === 4) mainPath = '/botw/main/homeNet/contract'
            if (userGroupCode === 1) mainPath = '/botw/main/homeNet'
            this.$router.push({ path: mainPath })
          } else {
            console.log('login success fail...')
            // success === false
            const msg = response.data.failMsg
            setTimeout(() => {
              this.$store.dispatch('snackbarShow', [msg, success])
            }, 150)
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.container{padding: 0 0 0 0 !important;}
#container{padding: 0 0 0 0 !important; position: absolute;width: 100%;height: 100%;left: 0px;overflow: hidden;margin: 0px 0px 0px 0px;background: url('~@/assets/images/login/bg-login.png') no-repeat;background-position: 50% 46%;vertical-align: middle;overflow: hidden;}
#topBar{width: 100%;height: 8px;background-image: linear-gradient(to right, #19d5ad, #1c7ad8);top:0;position : absolute;z-index: 100;}
#downBar{width: 100%;height: 55px;background-image: linear-gradient(to right, #19d5ad, #1c7ad8);bottom:0;position : absolute;z-index: 100;}
#downTitle{width: 100%;line-height: normal;height: 14px;font-family: roboto;font-size: 12px;text-align: center;color: #d1edf3;top: 21px;position: relative;}

/* .login-box{position: absolute;display: block;width: 440px;height: 700px;border-radius: 20px;box-shadow: 8px 25px 36px 0 rgba(0, 0, 0, 0.2);background-color: #ffffff;margin: 0 auto;top: 36%;left: 50%;margin-top: -244.5px;margin-left: -192px;} */
.login-box{display: block;width: 440px;height: 500px;border-radius: 20px;box-shadow: 8px 25px 36px 0 rgba(0, 0, 0, 0.2);background-color: #ffffff;margin: 0 auto;}
#titleArea{border-radius: 20px 20px 0px 0px;height: 238px;margin-bottom: 0px;}
/* #firstTitle{width: 100%;height: 24px;font-family: NotoSansKR;font-size: 16px;font-style: normal;font-stretch: normal;font-weight: 900;letter-spacing: -0.5px;color: #1c7ad8;text-align: center;padding-top: 56px;}
#secondTitle{display : block;height: 57px;font-family: robotom;font-size: 48px;font-weight: 600;text-align: center;color: #444444;}
#thirdTitle{top: 134px;width: 100%;text-align: center;height: 16px;font-family: roboto;font-size: 14px;color: #888888;} */

#logoTitle {/* background: url('~@/assets/images/logo/logo_duru_full.png'); */width: 176px;height: 72.6px;margin-left: auto;margin-right: auto;margin-top: 17%;margin-bottom: 5%;background-size: cover;flex-grow: 0;object-fit: contain;transform: scale(1.5);}
#firstDivider{width: 362px;height: 24px;background: url('~@/assets/images/login/divider-login-diagonal.png') no-repeat;margin-bottom: 24px;margin-left: 32px;margin-right: 32px;background-size: cover;}
#formArea{background: none;padding:0;width: 100%;}

.fieldArea{display: block;height: 42px;margin-bottom:16px;padding:0 50px;}

.selectFieldArea{display: block;width: 362px;height: 42px;margin: 0 auto;padding:0;margin-bottom: 16px;}

.fieldArea >>> .v-icon.mdi-id-icon{background: url('~@/assets/images/login/icon-id.png') no-repeat;width:24px;height:24px;margin-left:5px;}
.fieldArea >>> .v-icon.mdi-pw-icon{background: url('~@/assets/images/login/icon-pw.png') no-repeat;width:24px;height:24px;margin-left:5px;}
.fieldArea >>> .v-text-field--outlined .v-input__slot > fieldset {border-radius:20px;}
.fieldArea >>> .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner {width:50px;margin-top:6px;}
.fieldArea >>> .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {margin-top:6px;margin-right:5px;}
.fieldArea >>> .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner .ic_delete {margin-top:3px;}

.seper {display: block;width: 362px;height: 1px;background-color: #cccccc;margin: 0 auto;margin-top: 30px;}

.buttonArea {width:325px;height: 42px;margin: 30px auto 0;}
.buttonArea >>> .v-btn {background-image: url('~@/assets/images/login/rectangle.png');font-family: Pretendard;font-weight:bold;}
.buttonArea >>> .v-btn.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {opacity:1;}
.buttonArea >>> .mdi-arrow-icons {background: url('~@/assets/images/login/icon-arrow-r.png') no-repeat;width:24px;height:24px;}

.login-from >>> .v-messages__message {text-align:right;}
</style>
