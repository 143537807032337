import Vue from 'vue'

const state = {
  baseUri: '/botw/main/admin/'
}
const getters = {
}
const actions = {
  currContract: function (_context, [paramData, callback]) { // 계약관리자 메인화면-계약 금년도 조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'currContract',
      params: paramData.orderContractCompSeq
    }
    console.log('currContract', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  prevContract: function (_context, [paramData, callback]) { // 계약관리자 메인화면-계약 전년도 조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'prevContract',
      params: paramData.orderContractCompSeq
    }
    console.log('prevContract', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  currRegionCheck: function (_context, [paramData, callback]) { // 계약관리자 메인화면-계약 금월 조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'currRegionCheck',
      params: paramData.orderContractCompSeq
    }
    console.log('currRegionCheck', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  prevRegionCheck: function (_context, [paramData, callback]) { // 계약관리자 메인화면-계약 전월 조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'prevRegionCheck',
      params: paramData.orderContractCompSeq
    }
    console.log('prevRegionCheck', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
