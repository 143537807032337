<template>
  <v-dialog scrollable :width="width" v-bind="$attrs" v-on="$listeners" v-bind:value="value">
    <v-card class="g-dialog_card">
      <v-card-title v-if="title">
        <v-flex>
          <v-layout align-center fill-height class="title-text">
            {{ title }}
          </v-layout>
        </v-flex>
        <v-spacer></v-spacer>
        <!-- 메인 비밀번호 변경 팝업에서 X 버튼 미표출 -->
        <v-flex v-if="title !== $t('user.imsiPassChange')">
          <v-layout v-if="closeBtn" justify-end align-center class="close-wrapper">
            <slot name="topBtns"></slot>
            <v-btn icon @click="dialogClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-layout>
        </v-flex>
      </v-card-title>
      <!-- pdf Down 진행 전 goto 스크롤을 scrollTop 0을 위한 컨테이너 id값 추가 -->
      <v-card-text :id="idText !== '' ? `g-dialog_text_card_${idText}` : 'g-dialog_text_card'" :style="textHeight !== undefined && textHeight !== null ? 'height:' + textHeight + 'px; ' + etcStyle : '' + etcStyle">
        <slot name="text"></slot>
      </v-card-text>

      <v-card-actions v-if="actionFlag">
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'GDialog',
  inheritAttrs: true,
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    title: {
      type: String,
      required: false
    },
    width: {
      type: String,
      required: false,
      default: '600'
    },
    closeBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    actionFlag: {
      type: Boolean,
      required: false,
      default: true
    },
    textHeight: {
      type: Number,
      required: false
    },
    // goTo scroll 동적 id값을 위한 props 추가
    idText: {
      type: String,
      required: false,
      default: ''
    },
    etcStyle: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
  }),
  created () {
  },
  mounted () {
    (function () { // make vuetify dialogs movable
      const d = {}
      document.addEventListener('mousedown', e => {
        const closestDialog = e.target.closest('.v-dialog.v-dialog--active')
        if (e.button === 0 && closestDialog != null && (e.target.classList.contains('v-card__title') || e.target.classList.contains('title-text'))) { // element which can be used to move element
          d.el = closestDialog // element which should be moved
          d.mouseStartX = e.clientX
          d.mouseStartY = e.clientY
          d.elStartX = d.el.getBoundingClientRect().left
          d.elStartY = d.el.getBoundingClientRect().top
          d.el.style.position = 'fixed'
          d.el.style.margin = 0
          d.oldTransition = d.el.style.transition
          d.el.style.transition = 'none'
        }
      })
      document.addEventListener('mousemove', e => {
        if (d.el === undefined) return
        d.el.style.left = Math.min(
          Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
          window.innerWidth - d.el.getBoundingClientRect().width
        ) + 'px'
        d.el.style.top = Math.min(
          Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
          window.innerHeight - d.el.getBoundingClientRect().height
        ) + 'px'
      })
      document.addEventListener('mouseup', () => {
        if (d.el === undefined) return
        d.el.style.transition = d.oldTransition
        d.el = undefined
      })
      setInterval(() => { // prevent out of bounds
        const dialog = document.querySelector('.v-dialog.v-dialog--active')
        if (dialog === null) return
        dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + 'px'
        dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + 'px'
      }, 100)
    })()
  },
  watch: {
  },
  computed: {
  },
  methods: {
    dialogClose: function () {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped>
/* .v-dialog > .v-card > .v-card__title {padding:0 0 0 16px;font-size: 16px;}
.g-dialog_card {overflow: hidden;}
.g-dialog_card >>> .v-card__title {height:40px;line-height:40px;background-color: #fff;font-size: 16px;font-weight: normal;font-style: normal;font-stretch: normal;letter-spacing: normal;color: #292929;border-bottom:1px solid #292929;cursor: grab;}
.g-dialog-search_card >>> .v-card__title:active {cursor: grabbing;}
.g-dialog_card >>> .v-card__title .title {width:calc(100% - 56px);}
.g-dialog_card >>> .v-card__title .title .title-text {display: block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.g-dialog_card >>> .v-card__title .close-wrapper {margin-right:4px;}

.g-dialog_card >>> .brower-error-content {padding:16px;}
.g-dialog_card >>> .v-card__text {color:#888;background-color:#fff;}
.g-dialog_card.v-card > .v-card__text {padding:16px;min-height:100px;} */
.g-dialog_card {padding:12px 24px 24px;}
.g-dialog_card >>> .v-card__title {padding:0;font-size: 16px;height:48px;line-height:48px;background-color: #fff;font-family: Pretendard !important;font-size: 20px !important;font-weight: 600 !important;font-style: normal;font-stretch: normal;letter-spacing: normal;color: #444;border-bottom:1px solid #000;cursor: grab;}
.g-dialog_card >>> .v-card__title .title .title-text {display: block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}

.g-dialog_card >>> .v-card__text {color:#888;background-color:#fff;min-height:100px;padding:16px 0;overflow-x: hidden;overflow-y: auto;}

.v-dialog > .v-card > .v-card__actions {padding:10px;}
.g-dialog_card >>> .v-card__actions {padding:0;width:100%;border-top:1px solid #cecece;text-align: center;display: block;}
.g-dialog_card >>> .v-card__actions button {min-width: 104px;height:48px;border:0;font-size: 16px;font-weight: 600;font-style: normal;font-stretch: normal;letter-spacing: normal;text-align: center;}
.g-dialog_card >>> .v-card__actions .v-btn + .v-btn {margin:0;}
.g-dialog_card >>> .v-card__actions .v-btn:not(.v-btn--depressed):not(.v-btn--flat) {box-shadow:none;-webkit-box-shadow:none;}
.g-dialog_card >>> .v-card__actions .flex button.v-btn--block {border-radius: 0;}

.g-dialog_card >>> .v-input--is-readonly input {color: #999;}
.g-dialog_card >>> .v-input--is-readonly .v-label {color: #999;}
.g-dialog_card >>> .v-input--is-readonly > .v-input__control > .v-input__slot:before {border-color: #bbb;}
</style>
