import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// 메인
// 현장관리자
import spotMain from './modules/main/homeNet/spot/actions.js'
// 계약관리자
import contractMain from './modules/main/homeNet/contract/actions.js'
// 원격점검자
import remoteMain from './modules/main/homeNet/remote/actions.js'

// 정보통신
// 현장정보
import spotInfoCheckFacility from './modules/icfacilities/spotInfo/checkFacility/actions.js'
import spotInfoContract from './modules/icfacilities/spotInfo/contract/actions.js'

// 유지보수점검
// 점검실시
import maintainCheck from './modules/icfacilities/maintain/check/actions.js'
// 점검이력
import maintainHistory from './modules/icfacilities/maintain/history/actions.js'

// 성능점검
import performCheck from './modules/icfacilities/perform/check/actions.js'
import performHistory from './modules/icfacilities/perform/history/actions.js'

// 설비고장
import errProcess from './modules/icfacilities/err/process/actions.js'
import errHistory from './modules/icfacilities/err/history/actions.js'

// 홈네트워크
// 계약정보
import homeNetCheck from './modules/homeNet/check/actions.js'
// 점검현황
import homeNetContract from './modules/homeNet/contract/actions.js'

// 설정
import configCheck from './modules/config/icfacilities/check/actions.js'
import configCode from './modules/config/system/code/actions.js'
import configCodeGroup from './modules/config/system/codeGroup/actions.js'
import configConsignComp from './modules/config/admin/consignComp/actions.js'
import configContract from './modules/config/icfacilities/contract/actions.js'
// import configFacility from './modules/config/facility/actions.js'
import configMenu from './modules/config/system/menu/actions.js'
import configSpot from './modules/config/admin/spot/actions.js'
import configSpotCheck from './modules/config/icfacilities/spotCheck/actions.js'
import configPerformCheck from './modules/config/icfacilities/performCheck/actions.js'
import configSpotFacility from './modules/config/icfacilities/spotFacility/actions.js'
// import configSystemConf from './modules/config/systemConf/actions.js'
import configUser from './modules/config/admin/user/actions.js'
import configUserGroup from './modules/config/system/userGroup/actions.js'
import configUserGroupPermission from './modules/config/system/userGroupPermission/actions.js'
import configHomeNetvisitComp from './modules/config/homeNet/visitComp/actions.js'
import configHomeNetContract from './modules/config/homeNet/contract/actions.js'
import configHomeNetContractComp from './modules/config/homeNet/contractComp/actions.js'

Vue.use(Vuex)

const state = {
  login: {
    userSeq: null,
    userName: '',
    userId: '',
    spotSeq: null, // 선택한 현장
    spotName: null, // 선택한 현장
    pwdChangeFlag: false, // 임시비밀번호 변경 여부
    loginKey: '', // 로그인키
    consignCompName: '',
    consignCompSeq: null,
    userGroupName: '', // 사용자그룹명
    userSpotGroupList: '', // 현장별 사용자그룹명
    encKey: '', // 암호화키
    menuList: [], // 로그인 메뉴 리스트,
    menuListGroup: [], // 시스템 관리자 메뉴 리스트,
    userTypeGroup: '',
    userGroupCode: '', // 사용자 계정 그룹코드
    mainComponentPath: '', // 메인 path
    componentGubun: '', // 유형 구분
    userGroupTitle: '', // 홈네트워크 타입
    componentTitle: '', // 홈네트워크 타입
    currentMenuTitle: '', // 선택한 메뉴 명칭
    currentMenuNavigation: '', // 선택한 메뉴 네비게이션
    currentMenuUpdateFlag: false, // 선택한 메뉴 수정권한 여부
    websocketConnection: null
    // userWebSocket: 'ws://localhost:8888/nhsims/websocket/user'
  },
  basicEncKey: 'GY-BOTW-RESTFU-SYSTEM#-AF1234DE9', // 기본 암호화 키
  basicEncIv: 'GY-BOTW-RESTFU-S', // 기본 iv 키
  snackbarFlag: false, // 스낵바 플래그
  snackbarTop: true, // 스낵바 top 위치 여부
  snackbarBottom: false, // 스낵바 bottom 위치 여부
  snackbarRight: true, // 스낵바 right 위치 여부
  snackbarVertical: false, // 스낵바 vertical 여부
  snackbarAutoHeight: true, // 스낵바 auto height 여부
  snackbarColor: '', // 스낵바 배경컬러
  snackbarMsg: '', // 스낵바 메시지,
  axiosLoading: false,
  dialogPopupFlag: true,
  dialogPopupTitle: false,
  dialogPopupMsg: false,
  dialogErrorTitle: '',
  dialogErrorMsg: '',
  dialogErrorFlag: false,
  dialogTimer: null,
  menu2ViewFlag: false,
  pdfDownStatus: false,
  pdfImgStatus: false,
  file: {
    fileTms: 0, // 파일 업로드 가능 총 용량
    fileMs: 0, // 파일 업로드 개별 가능 용량
    fileTc: 0 // 파일 업로드 최대 개수
  },
  showFormLayer: false,
  formLayerShow: false
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    'main/homeNet/spot': spotMain,
    'main/homeNet/contract': contractMain,
    'main/homeNet/remote': remoteMain,

    'spotInfo/checkFacility': spotInfoCheckFacility,
    'spotInfo/contract': spotInfoContract,
    'maintain/check': maintainCheck,
    'maintain/history': maintainHistory,
    'perform/check': performCheck,
    'perform/history': performHistory,
    'err/process': errProcess,
    'err/history': errHistory,

    'homeNet/check': homeNetCheck,
    'homeNet/contract': homeNetContract,

    'config/check': configCheck,
    'config/code': configCode,
    'config/codeGroup': configCodeGroup,
    'config/consignComp': configConsignComp,
    'config/contract': configContract,

    'config/menu': configMenu,
    'config/spot': configSpot,
    'config/spotCheck': configSpotCheck,
    'config/performCheck': configPerformCheck,
    'config/spotFacility': configSpotFacility,

    'config/user': configUser,
    'config/userGroup': configUserGroup,
    'config/userGroupPermission': configUserGroupPermission,

    'config/visitComp': configHomeNetvisitComp,
    'config/hnContract': configHomeNetContract,
    'config/contractComp': configHomeNetContractComp
  }
})
