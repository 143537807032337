import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// function loadLocaleMessages () {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ko',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ko',
  // messages: loadLocaleMessages()
  messages: {
    // en: Object.assign({}, require('./locales/en.json')),
    // ko: Object.assign({}, require('./locales/ko.json'))
    en: Object.assign({}, require('./locales/en.json'), require('./locales/config/en.json'), require('./locales/icfacilities/err/en.json'), require('./locales/icfacilities/maintain/en.json'), require('./locales/icfacilities/perform/en.json'), require('./locales/icfacilities/spotInfo/en.json'), require('./locales/homeNet/check/en.json'), require('./locales/homeNet/contract/en.json')),
    ko: Object.assign({}, require('./locales/ko.json'), require('./locales/config/ko.json'), require('./locales/icfacilities/err/ko.json'), require('./locales/icfacilities/maintain/ko.json'), require('./locales/icfacilities/perform/ko.json'), require('./locales/icfacilities/spotInfo/ko.json'), require('./locales/homeNet/check/ko.json'), require('./locales/homeNet/contract/ko.json'))
  },

  silentTranslationWarn: true
})

export { i18n }
