import Vue from 'vue'

// const file = require('@/assets/images/sampleImg.jpg')
const state = {
  baseUri: '/botw/main/spot/'
}
const getters = {
}
const actions = {
  completeTable: function (_context, [paramData, callback]) { // 현장관리자 메인화면-확인완료 조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'completeTable',
      params: paramData
    }
    console.log('completeTable', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  visitCheckTable: function (_context, [paramData, callback]) { // 현장관리자 메인화면-방문점검 조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'visitCheckTable',
      params: paramData
    }
    console.log('visitCheckTable', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  checkCompleteSearch: function (_context, [paramData, callback]) { // 현장관리자 메인화면-방문점검 조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'checkCompleteSearch',
      params: paramData
    }
    console.log('checkCompleteSearch', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  checkSearch: function (_context, [paramData, callback]) { // 현장관리자 메인화면-방문점검 조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'checkSearch',
      params: paramData
    }
    console.log('checkSearch', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  pdfDown: function (_context, [paramData, callback]) { // 점검 현황 - pdf 정보 조회
    const reqData = {
      historySeq: paramData.historySeq
    }
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'preview',
      params: reqData
    }
    console.log('pdfDown', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
