import Vue from 'vue'

const state = {
  baseUri: '/botw/hn/check/'
}
const getters = {
}
const actions = {
  codes: function (_context, [paramData, callback]) { // 검색조건 조회
    const reqData = {
      year: paramData.year,
      month: paramData.month
    }
    console.log('codes actions: ')
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'codes',
      params: reqData
    }
    console.log('actions codes : ', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  search: function (_context, [paramData, callback]) { // 점검 현황 - 리스트 조회
    const reqData = {
      pageNum: 1,
      rowCount: paramData.rowCount,
      year: paramData.year,
      month: paramData.month,
      spotSeq: paramData.spotSeq,
      remoteCheckStatusCodeIdArr: paramData.remoteCheckStatusCodeIdArr === '' ? null : paramData.remoteCheckStatusCodeIdArr,
      visitCheckStatusCodeIdArr: paramData.visitCheckStatusCodeIdArr === '' ? null : paramData.visitCheckStatusCodeIdArr,
      officeStatusCodeId: paramData.officeStatusCodeId
    }
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: reqData
    }
    console.log('actions search : ', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  pdfDown: function (_context, [paramData, callback]) { // 점검 현황 - pdf 정보 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'pdfDown?',
      params: paramData
    }
    console.log('homeNetCheck-pdfDown', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  getMaxErrHistorySeq: function (_context, [spotSeq, callback]) { // 상세조회
    const reqData = {
      spotSeq: spotSeq
    }
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'getMaxErrHistorySeq',
      params: reqData
    }
    console.log('homeNetCheck-getMaxErrHistorySeq', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [historySeq, callback]) { // 상세조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + historySeq
    }
    console.log('homeNetCheck-detail', historySeq, axiosOption.url)
    Vue.axios(axiosOption).then(callback)
  },
  update: function (_context, [paramData, callback]) { // 점검 현황 - 수정
    const reqData = {
      checkHistoryDtlList: paramData.checkHistoryDtlList,
      errList: paramData.errList
    }
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: reqData
    }
    console.log('homeNetCheck-update', axiosOption.data)
    Vue.axios(axiosOption).then(callback)
  },
  updateStatus: function (_context, [paramData, callback]) { // 점검 현황 - 일괄 원격점검 상태 업데이트
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'updateStatus',
      data: paramData
    }
    console.log('homeNetCheck-updateStatus', axiosOption.data)
    Vue.axios(axiosOption).then(callback)
  },
  // 미사용
  // updateStatusComp: function (_context, [paramData, callback]) { // 점검 현황 - 단일 원격점검 상태 업데이트
  //   const axiosOption = {
  //     method: 'POST',
  //     url: _context.state.baseUri + 'updateStatusComp',
  //     data: paramData
  //   }
  //   console.log('homeNetCheck-updateStatusComp: ', axiosOption.data)
  //   Vue.axios(axiosOption).then(callback)
  // },
  officeUpdate: function (_context, [paramData, callback]) { // 점검 현황 - 완료 상태 업데이트
    const reqData = {
      historySeq: paramData.historySeq,
      officeOpinion: paramData.officeOpinion
    }
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'officeUpdate',
      data: reqData
    }
    console.log('homeNetCheck-officeUpdate: ', axiosOption.data)
    Vue.axios(axiosOption).then(callback)
  },
  gimsSearch: function (_context, [paramData, callback]) { // 점검 현황 - 리스트 조회
    const reqData = {
      year: paramData.year,
      month: paramData.month
    }
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'gimsSearch',
      params: reqData
    }
    console.log('actions gimsSearch : ', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
