import Vue from 'vue'

const state = {
  baseUri: '/botw/config/spot/'
}
const getters = {
}
const actions = {
  codes: function (_context, [data, callback]) { // 지자체 조회
    console.log('codes actions: ', data)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'codes',
      params: data
    }
    Vue.axios(axiosOption).then(callback)
  },
  search: function (_context, [searchParam, callback]) { // 현장관리 리스트 조회
    const reqData = {
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount,
      dongName: searchParam.dongName,
      spotName: searchParam.spotName,
      spotGubun: searchParam.spotGubun,
      hnFlag: String(searchParam.hnFlag),
      duruFlag: String(searchParam.duruFlag)
    }

    console.log('actions search : ', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  searchDong: function (_context, [data, callback]) { // 법정동 조회
    const reqData = {
      level: data.level,
      upperDongCode: data.dongCode
    }
    console.log('searchDong actions: ', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'dongSearch',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [spotSeq, callback]) { // 현장 상세 조회
    console.log('detail actions: ', spotSeq)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + spotSeq
      // params: spotSeq
    }
    Vue.axios(axiosOption).then(callback)
  },
  gimsSearch: function (_context, [searchParam, callback]) { // gims코드 목록조회
    const reqData = {
      spotName: searchParam.spotName,
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    console.log('configSpot-gimsSearch', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'gimsSearch',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  contractCompSearch: function (_context, [searchParam, callback]) { // 계약업체 목록조회
    const reqData = {
      contractCompName: searchParam.contractCompName,
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    console.log('configSpot-contractCompSearch', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'contractCompSearch',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  consignCompSearch: function (_context, [searchParam, callback]) { // 위탁업체 목록조회
    const reqData = {
      consignCompName: searchParam.consignCompName,
      address: searchParam.address,
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    console.log('consignCompSearch : ', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'consignCompSearch',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  insert: function (_context, [param, callback]) { // 등록
    let reqData = {}
    if (param.spotGubun.indexOf('홈네트워크') >= 0 && param.spotGubun.indexOf('정보통신') >= 0) {
      reqData = {
        address: param.address,
        dongCode: param.dongCode,
        spotName: param.spotName,
        houseHoldNum: param.houseHoldNum,
        laeCodeId: param.laeCodeId,
        spotGubun: '홈네트워크,정보통신',
        spotType: param.spotType,
        pmName: param.pmName,
        modelName: param.modelName,
        contractCompSeq: Number(param.contractCompSeq),
        // tbFile: param.tbFile,
        fileCode: param.tbFile.fileCode,
        hnFlag: String(true),
        // 여기까지 공통+홈넷
        // 이하 정보통신만
        trustCheckFlag: String(param.trustCheckFlag),
        consignCompSeq: param.consignCompSeq,
        note: param.note,
        duruFlag: String(true)
      }
    } else if (param.spotGubun.indexOf('홈네트워크') >= 0 && param.spotGubun.indexOf('정보통신') < 0) {
      reqData = {
        address: param.address,
        dongCode: param.dongCode,
        spotName: param.spotName,
        houseHoldNum: param.houseHoldNum,
        laeCodeId: '',
        spotGubun: '홈네트워크',
        spotType: param.spotType,
        pmName: param.pmName,
        modelName: param.modelName,
        contractCompSeq: Number(param.contractCompSeq),
        // tbFile: param.tbFile,
        fileCode: param.tbFile.fileCode,
        hnFlag: String(true),
        // 여기까지 공통+홈넷
        // 이하 정보통신만
        trustCheckFlag: String(true),
        consignCompSeq: param.consignCompSeq,
        note: param.note,
        duruFlag: String(false)
      }
    } else if (param.spotGubun.indexOf('홈네트워크') < 0 && param.spotGubun.indexOf('정보통신') >= 0) {
      reqData = {
        address: param.address,
        dongCode: param.dongCode,
        spotName: param.spotName,
        houseHoldNum: param.houseHoldNum,
        laeCodeId: param.laeCodeId,
        spotGubun: '정보통신',
        spotType: param.spotType,
        pmName: param.pmName,
        modelName: param.modelName,
        contractCompSeq: Number(param.contractCompSeq),
        // tbFile: param.tbFile,
        fileCode: param.tbFile.fileCode,
        hnFlag: String(false),
        // 여기까지 공통+홈넷
        // 이하 정보통신만
        trustCheckFlag: String(true),
        consignCompSeq: param.consignCompSeq,
        note: param.note,
        duruFlag: String(true)
      }
    }

    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: reqData
    }
    console.log('insert reqData: ', axiosOption.data)
    Vue.axios(axiosOption).then(callback)
  },
  update: function (_context, [param, callback]) { // 수정
    let reqData = {}
    if (param.spotGubun.indexOf('홈네트워크') >= 0 && param.spotGubun.indexOf('정보통신') >= 0) {
      reqData = {
        spotSeq: param.spotSeq,
        spotName: param.spotName,
        spotGubun: param.spotGubun,
        houseHoldNum: param.houseHoldNum,
        trustCheckFlag: String(param.trustCheckFlag),
        consignCompSeq: param.consignCompSeq,
        note: param.note,
        spotType: param.spotType,
        pmName: param.pmName,
        modelName: param.modelName,
        laeCodeId: param.laeCodeId,
        contractCompSeq: Number(param.contractCompSeq),
        // tbFile: param.tbFile,
        fileCode: param.tbFile.fileCode,
        hnFlag: String(param.hnFlag),
        duruFlag: String(param.duruFlag)
      }
    } else if (param.spotGubun.indexOf('홈네트워크') >= 0 && param.spotGubun.indexOf('정보통신') < 0) {
      reqData = {
        spotSeq: param.spotSeq,
        spotName: param.spotName,
        spotGubun: param.spotGubun,
        houseHoldNum: param.houseHoldNum,
        trustCheckFlag: String(param.trustCheckFlag),
        consignCompSeq: param.consignCompSeq,
        note: param.note,
        spotType: param.spotType,
        pmName: param.pmName,
        modelName: param.modelName,
        laeCodeId: '',
        contractCompSeq: Number(param.contractCompSeq),
        // tbFile: param.tbFile,
        fileCode: param.tbFile.fileCode,
        hnFlag: String(param.hnFlag),
        duruFlag: String(false)
      }
    } else if (param.spotGubun.indexOf('홈네트워크') < 0 && param.spotGubun.indexOf('정보통신') >= 0) {
      reqData = {
        spotSeq: param.spotSeq,
        spotName: param.spotName,
        spotGubun: param.spotGubun,
        houseHoldNum: param.houseHoldNum,
        trustCheckFlag: String(param.trustCheckFlag),
        consignCompSeq: param.consignCompSeq,
        note: param.note,
        spotType: param.spotType,
        pmName: param.pmName,
        modelName: param.modelName,
        laeCodeId: param.laeCodeId,
        contractCompSeq: Number(param.contractCompSeq),
        // tbFile: param.tbFile,
        fileCode: param.tbFile.fileCode,
        hnFlag: String(false),
        duruFlag: String(param.duruFlag)
      }
    }
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: reqData
    }
    console.log('update reqData: ', axiosOption.data)
    Vue.axios(axiosOption).then(callback)
  },
  descInsert: function (_context, [param, callback]) { // 특이사항 등록
    const reqData = {
      // insertUserSeq: param.insertUserSeq,
      spotSeq: param.spotSeq,
      desc: param.desc
    }

    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'descInsert',
      data: reqData
    }
    console.log('descInsert reqData : ', axiosOption.data)
    Vue.axios(axiosOption).then(callback)
  },
  descUpdate: function (_context, [param, callback]) { // 특이사항 수정
    const reqData = {
      // updateUserSeq: param.updateUserSeq,
      spotDtlSeq: param.spotDtlSeq,
      spotSeq: param.spotSeq,
      desc: param.desc
    }

    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'descUpdate',
      data: reqData
    }
    console.log('descUpdate reqData : ', axiosOption.data)
    Vue.axios(axiosOption).then(callback)
  },
  descDelete: function (_context, [param, callback]) { // 특이사항 삭제
    const reqData = {
      spotSeq: param.spotSeq,
      spotDtlSeq: param.spotDtlSeq
    }

    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'descDelete',
      data: reqData
    }
    console.log('descDelete reqData : ', axiosOption.data)
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
