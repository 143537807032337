import Vue from 'vue'

const state = {
  baseUri: '/botw/config/consignComp/'
}
const getters = {
}
const actions = {
  codes: function (_context, [data, callback]) { // 지자체 조회
    console.log('codes actions: ', data)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'codes',
      params: data
    }
    Vue.axios(axiosOption).then(callback)
  },
  searchDong: function (_context, [data, callback]) { // 법정동 조회
    const reqData = {
      level: data.level,
      upperDongCode: data.dongCode
    }
    const axiosOption = {
      method: 'GET',
      url: '/botw/config/consignComp/dongSearch',
      params: reqData
    }
    console.log('searchDong actions: ', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  search: function (_context, [searchParam, callback]) { // 내역 조회
    const reqData = {
      consignCompName: searchParam.consignCompName,
      hnDuruGubun: searchParam.hnDuruGubun,
      hnFlag: String(searchParam.hnFlag),
      duruFlag: String(searchParam.duruFlag),
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: reqData
    }
    console.log('actions search : ', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [consignCompSeq, callback]) { // 상세 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + consignCompSeq
    }
    Vue.axios(axiosOption).then(callback)
  },
  insert: function (_context, [data, callback]) { // 등록
    let reqData = {}
    if (data.hnDuruGubun.indexOf('홈네트워크') >= 0 && data.hnDuruGubun.indexOf('정보통신') >= 0) {
      reqData = {
        hnDuruGubun: data.hnDuruGubun,
        consignCompName: data.consignCompName,
        businessNum: data.businessNum,
        representative: data.representative,
        mainPhoneNum: data.mainPhoneNum,
        fax: data.fax,
        email: data.email,
        dongCode: data.dongCode,
        extraAddress: data.extraAddress,
        // tbFile: data.tbFile,
        fileCode: data.tbFile.fileCode,
        note: data.note,
        hnFlag: String(true),
        duruFlag: String(true)
      }
    } else if (data.hnDuruGubun.indexOf('홈네트워크') >= 0 && data.hnDuruGubun.indexOf('정보통신') < 0) {
      reqData = {
        hnDuruGubun: data.hnDuruGubun,
        consignCompName: data.consignCompName,
        businessNum: data.businessNum,
        representative: data.representative,
        mainPhoneNum: data.mainPhoneNum,
        fax: data.fax,
        email: data.email,
        dongCode: data.dongCode,
        extraAddress: data.extraAddress,
        // tbFile: data.tbFile,
        fileCode: data.tbFile.fileCode,
        note: data.note,
        hnFlag: String(true),
        duruFlag: String(false)
      }
    } else if (data.hnDuruGubun.indexOf('홈네트워크') < 0 && data.hnDuruGubun.indexOf('정보통신') >= 0) {
      reqData = {
        hnDuruGubun: data.hnDuruGubun,
        consignCompName: data.consignCompName,
        businessNum: data.businessNum,
        representative: data.representative,
        mainPhoneNum: data.mainPhoneNum,
        fax: data.fax,
        email: data.email,
        dongCode: data.dongCode,
        extraAddress: data.extraAddress,
        // tbFile: data.tbFile,
        fileCode: data.tbFile.fileCode,
        note: data.note,
        hnFlag: String(false),
        duruFlag: String(true)
      }
    }
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: reqData
    }
    console.log('insert reqData: ', axiosOption.data)
    Vue.axios(axiosOption).then(callback)
  },
  update: function (_context, [data, callback]) { // 수정
    const reqData = {
      hnDuruGubun: data.hnDuruGubun,
      consignCompSeq: data.consignCompSeq,
      consignCompName: data.consignCompName,
      businessNum: data.businessNum,
      representative: data.representative,
      mainPhoneNum: data.mainPhoneNum,
      fax: data.fax,
      email: data.email,
      dongCode: data.dongCode,
      extraAddress: data.extraAddress,
      // tbFile: data.tbFile,
      fileCode: data.tbFile.fileCode,
      note: data.note,
      hnFlag: String(data.hnFlag),
      duruFlag: String(data.duruFlag)
    }
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: reqData
    }
    console.log('consignComp-update data: ', axiosOption.data)
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
