<template>
  <v-app>
    <router-view/>
    <g-dialog v-model="$store.state.dialogErrorFlag" :title="$store.state.dialogErrorTitle" @close="dialogErrorClose" :actionFlag="true" :authCheck="false">
      <template v-slot:text>
        <div v-html="$store.state.dialogErrorMsg" class="brower-error-content"></div>
      </template>
      <template v-slot:actions>
        <v-btn color="primary" style="width: 100% !important" @click="dialogErrorClose">{{ $t('btns.close')  }}</v-btn>
      </template>
    </g-dialog>

    <!-- loading -->
    <v-overlay v-if="$store.state.axiosLoading" :opacity="0"></v-overlay>
    <v-dialog v-model="loadingIconShow" persistent width="120">
      <v-card color="primary" dark>
        <v-card-text class="pt-3">
          Waiting...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <g-snackbar :value="$store.state.snackbarFlag" @input="snackbarClose" :infoFlag="$store.state.snackbarColor === 'success' ? true : false">
      <template v-slot:text>
        <span v-html="$store.state.snackbarMsg"></span>
      </template>
    </g-snackbar>
  </v-app>
</template>

<script>
import GDialog from '@/components/dialog/GDialog.vue'
import GSnackbar from './components/dialog/GSnackbar.vue'
import * as am5 from '@amcharts/amcharts5'

export default {
  name: 'App',
  components: {
    GDialog,
    GSnackbar
  },
  data () {
    return {
      icons: {
        closeIcon: require('../src/assets/images/icon/dialog_close.png')
      },
      loadingIconShow: false,
      loadingDialogOpacity: 0,
      timeObj: 0
    }
  },
  computed: {
    getAxiosLoading: function () {
      return this.$store.state.axiosLoading
    }
  },
  created () {
    const chartLicenseKey = process.env.VUE_APP_CHART_KEY
    if (chartLicenseKey.length > 0) am5.addLicense(chartLicenseKey)
  },
  watch: {
    getAxiosLoading (val) {
      if (val) {
        // 서버 연동이 0.7초 이상 소요 시 로딩 아이콘 표기
        this.timeObj = setTimeout(() => {
          if (this.$store.state.axiosLoading) {
            this.loadingIconShow = true
            this.loadingDialogOpacity = 0.3
          }
        }, 700)
      } else {
        if (this.timeObj !== null) {
          clearTimeout(this.timeObj)
          this.timeObj = 0
        }
        this.loadingIconShow = false
        this.loadingDialogOpacity = 0
      }
    }
  },
  mounted () {
    const self = this
    const agent = navigator.userAgent
    let match
    let deviceType, browser, version

    if (/Android/i.test(navigator.userAgent)) {
      deviceType = 'android'
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      deviceType = 'ios'
    } else {
      deviceType = 'pc'
    }

    if ((match = agent.match(/MSIE ([0-9]+)/)) || (match = agent.match(/Trident.*rv:([0-9]+)/))) {
      browser = 'Internet Explorer'
    } else if ((match = agent.match(/OPR\/([0-9]+)/)) || (match = agent.match(/Opera\/([0-9]+)/))) {
      browser = 'Opera'
    } else if ((match = agent.match(/Chrome\/([0-9]+)/))) {
      browser = 'Chrome'
    } else if ((match = agent.match(/Firefox\/([0-9]+)/))) {
      browser = 'Firefox'
    } else if ((match = agent.match(/Safari\/([0-9]+)/))) {
      browser = 'Safari'
    } else if ((match = agent.match(/Edge\/([0-9]+)/))) {
      browser = 'Edge'
    } else {
      browser = 'Unknown'
    }

    if (browser !== 'Unknown') {
      if (browser === 'Safari') {
        version = agent.match(/Version\/([0-9]+)/)[1]
      } else {
        version = match[1]
      }
    }

    console.log('deviceType : ' + deviceType)
    console.log('Browser: ' + browser)
    console.log('Version: ' + version)
    self.$store.commit('setConnectInfo', { deviceType: deviceType, browser: browser, version: version })
    self.connectCheck(deviceType, browser, version)
  },
  methods: {
    connectCheck: function (deviceType, browser, version) {
      let msg = ''
      if (deviceType === 'pc') {
        if (browser === 'Internet Explorer' && version < 10) {
          msg = this.$t('error.browser', { browser: browser, version: 10 })
        } else if (browser === 'Opera' && version < 30) {
          msg = this.$t('error.browser', { browser: browser, version: 30 })
        } else if (browser === 'Chrome' && version < 45) {
          msg = this.$t('error.browser', { browser: browser, version: 45 })
        } else if (browser === 'Firefox' && version < 38) {
          msg = this.$t('error.browser', { browser: browser, version: 38 })
        } else if (browser === 'Safari' && version < 9) {
          msg = this.$t('error.browser', { browser: browser, version: 9 })
        } else if (browser === 'Edge' && version < 12) {
          msg = this.$t('error.browser', { browser: browser, version: 12 })
        } else if (browser === 'Unknown') {
          msg = this.$t('error.unknownBrowser')
        }
      }
      console.log(msg)
      if (msg !== '') {
        try {
          this.$store.dispatch('showErrorDialog', { title: 'Browser Error', message: msg, autoClose: false })
        } catch (e) {
          alert(msg.replace(/(<br>|<br\/>|<br \/>)/g, '\r\n'))
        }
      }
    },
    dialogErrorClose: function () {
      this.$store.dispatch('closeErrorDialog')
    },
    snackbarClose: function () {
      this.$store.dispatch('snackbarClose')
    }
  }
}
</script>

<style scoped>
.brower-error-content {color:#444;height:150px;padding:30px 16px;font-family: Pretendard;}

.g-snack >>> .v-snack__content {padding:16px 10px 6px 16px;width:100%;}
.g-snack >>> .v-snack--vertical > .v-snack__wrapper > .v-snack__content .v-btn.v-btn {margin-top:0;padding:3px 8px 0 0;}
.g-snack >>> .v-btn:not(.v-btn--round).v-size--default {min-width: 32px;padding:0;}
.g-snack >>> .v-snack.v-snack--active {padding: 8px 8px 0 8px;}
.g-snack >>> .v-snack__wrapper {border-radius: 5px;box-shadow: 0 2px 4px 0 #00000033;}
.g-snack >>> .v-snack__wrapper.success {background-color: #289bd8 !important;border-color: #289bd8 !important;}
.g-snack >>> .v-snack__wrapper.error {background-color: #e56747 !important;border-color: #e56747 !important;}
.g-snack >>> .v-snack__wrapper .img {width: 32px; height: 32px;border: 2px solid #fff; border-radius: 25px;margin-right:16px;}
.g-snack >>> .v-snack__wrapper .img {background: url('~@/assets/images/sub/icon_ok.svg') no-repeat center center;}
.g-snack >>> .v-snack__wrapper.error .img {background: url('~@/assets/images/sub/icon_error.svg') no-repeat center center;}
.g-snack >>> .v-snack__wrapper.success .img {background: url('~@/assets/images/sub/icon_ok.svg') no-repeat center center;}
.g-snack >>> .v-snack--multi-line .v-snack__content {padding:32px 0 32px 16px;}

@media (min-width: 600px) {
  .g-snack >>> .v-snack.v-snack--active {padding: 0;}
  .g-snack >>> .v-snack__wrapper {border-radius: 5px;box-shadow: 0 2px 4px 0 #00000033;}
  .g-snack >>> .v-snack--multi-line .v-snack__content {min-width: 359px !important;}
}
</style>
