import Vue from 'vue'

const state = {
  baseUri: '/botw/config/contractComp/'
}
const getters = {
}
const actions = {
  searchDong: function (_context, [data, callback]) { // 법정동 조회
    const reqData = {
      level: data.level,
      upperDongCode: data.dongCode
    }
    console.log('searchDong actions: ', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'dongSearch',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  search: function (_context, [searchParam, callback]) { // 내역 조회
    const reqData = {
      contractCompName: searchParam.contractCompName,
      adress: searchParam.adress,
      dataFlag: String(searchParam.dataFlag),
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    console.log('actions search : ', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [contractCompSeq, callback]) { // 상세 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + contractCompSeq
    }
    Vue.axios(axiosOption).then(callback)
  },
  insert: function (_context, [data, callback]) { // 등록
    const reqData = {
      contractCompName: data.contractCompName,
      businessNum: data.businessNum,
      representative: data.representative,
      mainPhoneNum: data.mainPhoneNum,
      fax: data.fax,
      email: data.email,
      dongCode: data.dongCode,
      extraAddress: data.extraAddress,
      dataFlag: String(true),
      // tbFile: data.tbFile,
      fileCode: data.tbFile.fileCode
    }
    console.log('insert data: ', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  update: function (_context, [data, callback]) { // 수정
    const reqData = {
      contractCompSeq: data.contractCompSeq,
      contractCompName: data.contractCompName,
      representative: data.representative,
      mainPhoneNum: data.mainPhoneNum,
      fax: data.fax,
      email: data.email,
      dongCode: data.dongCode,
      extraAddress: data.extraAddress,
      dataFlag: String(data.dataFlag),
      // tbFile: data.tbFile,
      fileCode: data.tbFile.fileCode,
      descList: data.descList
    }
    console.log('update data : ', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  descInsert: function (_context, [param, callback]) { // 특이사항 등록
    const reqData = {
      // insertUserSeq: param.insertUserSeq,
      contractCompSeq: param.contractCompSeq,
      desc: param.desc
    }
    console.log('descInsert reqData : ', reqData)

    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'descInsert',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  descUpdate: function (_context, [param, callback]) { // 특이사항 수정
    const reqData = {
      // updateUserSeq: param.updateUserSeq,
      contractCompSeq: param.contractCompSeq,
      contractCompDtlSeq: param.contractCompDtlSeq,
      desc: param.desc
    }
    console.log('descUpdate reqData : ', reqData)

    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'descUpdate',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  descDelete: function (_context, [param, callback]) { // 특이사항 삭제
    const reqData = {
      contractCompSeq: param.contractCompSeq,
      contractCompDtlSeq: param.contractCompDtlSeq
    }
    console.log('descDelete reqData : ', reqData)

    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'descDelete',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
