import Vue from 'vue'

const state = {
  baseUri: '/botw/config/hnContract/'
}
const getters = {
}
const actions = {
  codes: function (_context, [callback]) { // 지자체 조회
    console.log('codes actions: ')
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'codes'
    }
    console.log('actions codes : ', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  search: function (_context, [searchParam, callback]) { // 내역 조회
    const reqData = {
      spotName: searchParam.spotName,
      contractStateCodeId: searchParam.contractStateCodeId,
      directYn: String(searchParam.directYn),
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: reqData
    }
    console.log('actions search : ', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  spotSearch: function (_context, [searchParam, callback]) { // spot코드 목록조회
    const reqData = {
      spotName: searchParam.spotName,
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'userSpotList',
      params: reqData
    }
    console.log('configSpot-spotSearch', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  contractCompSearch: function (_context, [searchParam, callback]) { // 계약업체 목록조회
    const reqData = {
      contractCompName: searchParam.contractCompName,
      address: searchParam.address,
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    const axiosOption = {
      method: 'GET',
      url: '/botw/config/contractComp/search/',
      params: reqData
    }
    console.log('configSpot-contractCompSearch', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  visitCompSearch: function (_context, [searchParam, callback]) { // 계약업체 목록조회
    const reqData = {
      visitCompName: searchParam.visitCompName,
      address: searchParam.address,
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    console.log('configSpot-visitCompCompSearch', reqData)
    const axiosOption = {
      method: 'GET',
      url: '/botw/config/visitComp/search/',
      params: reqData
    }
    console.log('configSpot-visitCompCompSearch', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  consignCompSearch: function (_context, [searchParam, callback]) { // 위탁업체 조회
    const reqData = {
      consignCompName: searchParam.consignCompName,
      address: searchParam.address,
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    const axiosOption = {
      method: 'GET',
      url: '/botw/config/consignComp/search/',
      params: reqData
    }
    console.log('configSpot-consignCompSearch', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [consignCompSeq, callback]) { // 계약서 상세조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + consignCompSeq
    }
    Vue.axios(axiosOption).then(callback)
  },
  insert: function (_context, [data, callback]) { // 등록
    const reqData = {
      spotSeq: data.spotSeq,
      contractCode: data.contractCode,
      contractCycle: data.contractCycle,
      contractEquation: data.contractEquation,
      contractRange: data.contractRange,
      contractPrice: data.contractPrice,
      monthPrice: data.monthPrice,
      directYn: String(data.directYn),
      applyDate: data.applyDate,
      endDate: data.endDate,
      orderContractCompSeq: data.orderContractCompSeq,
      subContractCompSeq: data.subContractCompSeq,
      consignRangeCodeId: data.consignRangeCodeId,
      consignCompSeq: data.consignCompSeq,
      visitCompSeq: data.visitCompSeq,
      // tbFile: data.tbFile,
      fileCode: data.tbFile.fileCode
    }
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: reqData
    }
    console.log('insert data: ', axiosOption.data, data)
    Vue.axios(axiosOption).then(callback)
  },
  update: function (_context, [data, callback]) { // 수정
    const reqData = {
      spotSeq: data.spotSeq,
      contractSeq: data.contractSeq,
      contractEquation: data.contractEquation,
      contractCycle: data.contractCycle,
      contractRange: data.contractRange,
      contractPrice: data.contractPrice,
      monthPrice: data.monthPrice,
      directYn: String(data.directYn),
      applyDate: data.applyDate,
      endDate: data.endDate,
      contractStateCodeId: data.contractCancelYn === true ? 'CD00004' : 'CD00001',
      orderContractCompSeq: data.orderContractCompSeq,
      subContractCompSeq: data.subContractCompSeq,
      consignRangeCodeId: data.consignRangeCodeId,
      consignCompSeq: data.consignCompSeq,
      visitCompSeq: data.visitCompSeq,
      // tbFile: data.tbFile,
      fileCode: data.tbFile.fileCode
      // descList: data.descList
    }
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: reqData
    }
    console.log('update data: ', data, axiosOption.data)
    Vue.axios(axiosOption).then(callback)
  },
  descInsert: function (_context, [param, callback]) { // 특이사항 등록
    const reqData = {
      // insertUserSeq: param.insertUserSeq,
      contractSeq: param.contractSeq,
      desc: param.desc
    }
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'descInsert',
      data: reqData
    }
    console.log('descInsert reqData : ', axiosOption.data)
    Vue.axios(axiosOption).then(callback)
  },
  descUpdate: function (_context, [param, callback]) { // 특이사항 수정
    const reqData = {
      // updateUserSeq: param.updateUserSeq,
      contractDtlSeq: param.contractDtlSeq,
      contractSeq: param.contractSeq,
      desc: param.desc
    }
    console.log('descUpdate reqData : ', reqData)

    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'descUpdate',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  descDelete: function (_context, [param, callback]) { // 특이사항 삭제
    const reqData = {
      contractSeq: param.contractSeq,
      contractDtlSeq: param.contractDtlSeq
    }
    console.log('descDelete reqData : ', reqData)

    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'descDelete',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
