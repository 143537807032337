'use strict'

import Vue from 'vue'
import axios from 'axios'
import store from '@/vuex/store'
// import store from '@/vuexTest/store'
import router from '@/router'
import { i18n } from '../i18n'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.get['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.put['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.delete['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.preflightContinue = true
axios.defaults.crossDomain = true
// axios.defaults.withCredentials = true

// const https = require('https')
const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
  // withCredentials: true,
  baseURL: process.env.VUE_APP_BASE_URI || '',
  timeout: 100 * 1000 // 30
  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: false
  // })
}

const _axios = axios.create(config)

_axios.defaults.params = {
  // dummyDate: new Date().getTime()
}

_axios.interceptors.request.use(
  function (config) {
    // config.params.dummyDate = new Date().getTime()

    store.state.axiosLoading = true
    /*
    store.state.axiosResFlag = false
    // 0.7초 안에 응답이 오지 않을 경우 로딩아이콘 표기하기
    setTimeout(function () {
      if (!store.state.axiosResFlag) {
        store.state.axiosLoading = true
      }
    }, 700)
    */

    // 로그인 키가 존재 시 헤더 삽입하기
    const loginKey = store.state.login.loginKey
    if (loginKey !== '') {
      config.headers.loginKey = loginKey
    }

    // 서버 xsrf 보안 정보 삽입
    /*
    var xsrfHeaderName = store.state.xsrfHeaderName
    var xsrfHeaderValue = store.state.xsrfHeaderValue
    if (xsrfHeaderName !== '' && xsrfHeaderValue !== '') {
      config.headers[xsrfHeaderName] = xsrfHeaderValue
    }
    */
    config.headers.axios = true

    // 요청 정보 암호화하기
    if (config.data !== undefined && config.data !== null && config.headers['Content-Type'] !== 'multipart/form-data') {
      // console.log('config.data  : ', JSON.stringify(config.data))
      let encKey = store.state.login.encKey
      let encIv = ''
      if (encKey === '') {
        encKey = store.state.basicEncKey
        encIv = store.state.basicEncIv
      } else {
        encIv = encKey.substring(0, 16)
      }

      const key = window.getHex(encKey)
      const encData = window.getEncryptData(key, window.getHex(encIv), JSON.stringify(config.data))
      // console.log('encData : ', encData)
      const bodyParam = {
        data: encData
      }
      config.data = JSON.stringify(bodyParam)
    }
    return config
  },
  function (error) {
    // 요청 시 에러 발생하면 에러 팝업 띄우기
    store.state.axiosResFlag = true
    store.state.axiosLoading = false
    console.log('request error - ' + error)
    return store.dispatch('showErrorDialog', { title: 'Client Error', message: i18n.t('error.clientReqError') })
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    try {
      if (response.config.responseType !== 'arraybuffer' && response.config.responseType !== 'blob') {
        // 응답 데이터 복호화하기
        const data = response.data.data
        let encKey = store.state.login.encKey
        let encIv = ''
        if (encKey === '') {
          encKey = store.state.basicEncKey
          encIv = store.state.basicEncIv
        } else {
          encIv = encKey.substring(0, 16)
        }
        const key = window.getHex(encKey)
        const decData = window.getDecryptData(key, window.getHex(encIv), data)
        // console.log(decData)
        response.data = JSON.parse(decData)
      }
    } catch (e) {
    }
    store.state.axiosResFlag = true
    store.state.axiosLoading = false
    return response
  },
  function (error) {
    error = JSON.parse(JSON.stringify(error))
    console.log('error : ', error)
    store.state.axiosResFlag = true
    store.state.axiosLoading = false
    // console.log('error1 : ', process.env.VUE_APP_BASE_URI + 'loginForm', error)
    // console.log('error : ', error)
    let flag = false
    if (error.message !== undefined) {
      flag = true
    }
    if (!flag) {
      console.log('res-error-timeout')
      return store.dispatch('showErrorDialog', { title: i18n.t('error.timeoutTitle'), message: i18n.t('error.timeoutError') })
    }
    if (error.message.indexOf('401') >= 0) { // 로그인화면 이동 401
      store.dispatch('snackbarShow', [i18n.t('error.sessionIsNull'), false])
      // console.log('res-error-401')
      return router.push({ path: '/loginForm' }).catch(_err => {
        // console.log('router_err-401', _err)
        window.location.href = process.env.VUE_APP_BASE_URI + 'loginForm'
      })
    } else if (error.message.indexOf('423') >= 0) { // 중복로그인 423
      store.dispatch('snackbarShow', [i18n.t('error.loginDuplicate'), false])
      // console.log('res-error-423')
      return router.push({ path: '/loginForm' }).catch(_err => {
        // console.log('router_err-423', _err)
        window.location.href = process.env.VUE_APP_BASE_URI + 'loginForm'
      })
    } else if (error.message.indexOf('403') >= 0) { // 권한없음 403
      store.dispatch('snackbarShow', [i18n.t('error.unAuthorize'), false])
      // console.log('res-error-403')
      return router.push({ path: '/botw/error/403' }).catch(_err => {
        // console.log('router_err-403', _err)
        window.location.href = process.env.VUE_APP_BASE_URI + 'loginForm'
      })
    } else { // 기타 오류 발생
      let msg = ''
      if (error.message !== undefined && error.message.length > 0) {
        if (error.message.indexOf('504') >= 0) {
          msg = i18n.t('error.504')
        } else if (error.message.indexOf('404') >= 0) {
          msg = i18n.t('error.404')
        } else if (error.message.indexOf('400') >= 0) {
          msg = i18n.t('error.400')
        } else {
          msg = i18n.t('error.500')
        }
        return store.dispatch('showErrorDialog', { title: 'Server Error', message: msg })
      } else {
        return Promise.reject(error)
      }
    }
    // console.log('end-error')
    // return Promise.reject(error)
  }
)

const AxiosPlugin = {
  install (Vue, options) {
    Vue.axios = _axios
    window.axios = _axios

    Object.defineProperties(Vue.prototype, {
      axios: {
        get () {
          return _axios
        }
      },
      $axios: {
        get () {
          return _axios
        }
      }
    })
  }
}
Vue.use(AxiosPlugin)

export default AxiosPlugin

/*
// ie10 이하에서 에러가 발생하여 위처럼 수정.
Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      }
    },
    $axios: {
      get () {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
*/
