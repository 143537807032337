import Vue from 'vue'

const state = {
  baseUri: '/botw/hn/contract/'
}
const getters = {
}
const actions = {
  codes: function (_context, [callback]) { // 검색조건 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'codes'
    }
    console.log('homeNetContract-codes')
    Vue.axios(axiosOption).then(callback)
  },
  search: function (_context, [searchParam, callback]) { // 내역 조회
    const reqData = {
      dongCode: searchParam.dongCode,
      spotSeq: searchParam.spotSeq,
      contractCompSeq: searchParam.contractCompSeq,
      contractManagerSeq: searchParam.contractManagerSeq,
      visitCompSeq: searchParam.visitCompSeq,
      contractStateCodeId: searchParam.contractStateCodeId,
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: reqData
    }
    console.log('homeNetContract-search', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [contractSeq, callback]) { // 계약정보 상세조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + contractSeq
    }
    console.log('homeNetContract-detail', contractSeq, axiosOption.url)
    Vue.axios(axiosOption).then(callback)
  },
  historyList: function (_context, [spotSeq, callback]) { // 계약정보 이력조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'historyList/' + spotSeq
    }
    console.log('homeNetContract-historyList', spotSeq)
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
