import Vue from 'vue'

const state = {
  baseUri: '/botw/config/user/'
}
const getters = {
}
const actions = {
  search: function (_context, [searchParam, callback]) { // 내역 조회
    const reqData = {
      userGroupCode: searchParam.userGroupCode,
      userName: searchParam.userName,
      userId: searchParam.userId,
      dataFlag: String(searchParam.dataFlag),
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    console.log('actions search : ', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  userGroupSearch: function (_context, [callback]) { // 사용자그룹 목록 조회
    console.log('configUser-userGroupSearch')
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'userGroupSearch'
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [userSeq, callback]) { // 상세 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + userSeq
    }
    Vue.axios(axiosOption).then(callback)
  },
  spotList: function (_context, [spotSearchParam, callback]) { // 현장 조회
    console.log('spotList data: ', spotSearchParam)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'spotSearch',
      params: spotSearchParam
    }
    Vue.axios(axiosOption).then(callback)
  },
  consignCompSearch: function (_context, [searchParam, callback]) { // 위탁업체 조회
    const reqData = {
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    console.log('actions search : ', reqData)
    const axiosOption = {
      method: 'GET',
      url: '/botw/config/consignComp/search/',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  contractCompSearch: function (_context, [searchParam, callback]) { // 계약업체 목록조회
    const reqData = {
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    console.log('configSpot-contractCompSearch', reqData)
    const axiosOption = {
      method: 'GET',
      url: '/botw/config/contractComp/search/',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  visitCompCompSearch: function (_context, [searchParam, callback]) { // 계약업체 목록조회
    const reqData = {
      pageNum: searchParam.pageNum,
      rowCount: searchParam.rowCount
    }
    console.log('configSpot-visitCompCompSearch', reqData)
    const axiosOption = {
      method: 'GET',
      url: '/botw/config/visitComp/search/',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  insert: function (_context, [data, callback]) { // 등록
    const reqData = {
      userGroupCode: Number(data.userGroupCode),
      consignCompSeq: data.consignCompSeq === undefined ? '' : data.consignCompSeq,
      contractCompSeq: data.contractCompSeq === undefined ? '' : data.contractCompSeq,
      visitCompSeq: data.visitCompSeq === undefined ? '' : data.visitCompSeq,
      userId: data.userId,
      userPassword: data.userPassword,
      userName: data.userName,
      mobileNum: data.mobileNum,
      email: data.email,
      note: data.note,
      department: data.department,
      userGubun: data.userGubun,
      detailSpotList: data.detailSpotList,
      allSpotFlag: String(data.allSpotFlag),
      managerYn: String(data.managerYn),
      dataFlag: String(true)
    }
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: reqData
    }
    console.log('insert data: ', axiosOption.data, data)
    Vue.axios(axiosOption).then(callback)
  },
  update: function (_context, [data, callback]) { // 수정
    const reqData = {
      userGroupCode: Number(data.userGroupCode),
      consignCompSeq: data.consignCompSeq,
      contractCompSeq: data.contractCompSeq,
      visitCompSeq: data.visitCompSeq,
      userId: data.userId,
      userPassword: data.userPassword,
      userSeq: data.userSeq,
      userName: data.userName,
      mobileNum: data.mobileNum,
      email: data.email,
      note: data.note,
      department: data.department,
      userGubun: data.userGubun,
      detailSpotList: data.detailSpotList,
      allSpotFlag: data.allSpotFlag === undefined ? 'false' : String(data.allSpotFlag),
      managerYn: String(data.managerYn),
      dataFlag: String(data.dataFlag)
    }
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: reqData
    }
    console.log('update data: ', axiosOption.data)
    Vue.axios(axiosOption).then(callback)
  },
  dupCheck: function (_context, [userId, callback]) { // 아이디 중복체크
    console.log('dupCheck data: ', userId)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'dupCheck/' + userId
    }
    Vue.axios(axiosOption).then(callback)
  },
  passInit: function (_context, [data, callback]) { // 비밀번호 초기화
    console.log('passInit data: ', data)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'passInit',
      data: data
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
