import Vue from 'vue'
import Router from 'vue-router'
import store from '@/vuex/store'
import Login from './views/Login.vue'
// import store from '@/vuexTest/store'
// import Login from './views/LoginCopy.vue'

import _ from 'lodash'

Vue.use(Router)
const requireAuth = (title) => (to, from, next) => {
  const currentPath = to.path
  setTimeout(() => {
    const accessInfo = sessionStorage.getItem('botw_access_info')
    if (accessInfo !== undefined && accessInfo !== null && accessInfo !== '') {
      const loginInfo = JSON.parse(accessInfo)
      // console.log('accessInfo-Router1', loginInfo, to, from, next)
      store.state.login.mainComponentPath = loginInfo.mainComponentPath
      // 홈네트워크 점검현황 그룹 코드에 따른 component명
      store.state.login.componentTitle = loginInfo.componentTitle
      store.state.login.userSeq = loginInfo.userSeq
      store.state.login.spotSeq = loginInfo.spotSeq
      store.state.login.spotName = loginInfo.spotName
      store.state.login.userId = loginInfo.userId
      store.state.login.userName = loginInfo.userName
      store.state.login.loginKey = loginInfo.loginKey
      store.state.login.encKey = loginInfo.encKey
      store.state.login.menuList = loginInfo.menuList
      store.state.login.consignCompName = loginInfo.consignCompName
      store.state.login.userGroupCode = loginInfo.userGroupCode
      store.state.login.userTypeGroup = loginInfo.userTypeGroup
      store.state.login.menuListGroup = loginInfo.menuListGroup
      store.state.login.userGroupName = loginInfo.userGroupName
      store.state.login.pwdChangeFlag = loginInfo.pwdChangeFlag
      if ((from.path === '/loginForm' && currentPath === '/botw/main') || from.path === '/') {
        // console.log('accessInfo-Router2', loginInfo, to, from, next)
        store.state.login.mainComponentPath = loginInfo.mainComponentPath
        store.state.login.componentTitle = loginInfo.componentTitle
        store.state.login.userSeq = loginInfo.userSeq
        store.state.login.spotSeq = loginInfo.spotSeq
        store.state.login.spotName = loginInfo.spotName
        store.state.login.userId = loginInfo.userId
        store.state.login.userName = loginInfo.userName
        store.state.login.loginKey = loginInfo.loginKey
        store.state.login.encKey = loginInfo.encKey
        store.state.login.menuList = loginInfo.menuList
        store.state.login.consignCompName = loginInfo.consignCompName
        store.state.login.userGroupCode = loginInfo.userGroupCode
        store.state.login.userGroupName = loginInfo.userGroupName
        store.state.login.pwdChangeFlag = loginInfo.pwdChangeFlag
        store.state.login.userTypeGroup = loginInfo.userTypeGroup
        store.state.login.menuListGroup = loginInfo.menuListGroup

        let flag = false
        // console.log('currentPath : ', currentPath)
        _.map(loginInfo.menuList, function (menu1) {
          _.map(menu1.items, function (menu2) {
            if (menu2.items === undefined || menu2.items === null) { // depth가 2인 경우
              if (menu2.menuPath.indexOf(currentPath) >= 0) {
                // console.log('check1')
                flag = true
                // groupPermission의 경우 0 - 권한없음  // 1 -  읽기 //  2 - 읽기/쓰기
                const updateFlag = menu2.groupPermission === 2
                store.state.currentMenuUpdateFlag = updateFlag
                return null
              }
            } else {
              _.map(menu2.items, function (menu3) { // 메뉴 depth가 3인 경우
                if (menu3.menuPath.indexOf(currentPath) >= 0) {
                  // console.log('check2')
                  flag = true
                  const menuName = menu3.menuName
                  const updateFlag = menu3.groupPermission === 2
                  // console.log('check2-updateFlag', JSON.parse(JSON.stringify(menu3)))
                  store.state.currentMenuTitle = menuName
                  store.state.currentMenuUpdateFlag = updateFlag
                  return null
                }
              })
            }
            if (flag) {
              return null
            }
          })
          if (flag) {
            return null
          }
        })
        return next()
      } else if (from.path !== '/') {
        // console.log('accessInfo-Router3', loginInfo.pwdChangeFlag, to, from, next)
        if (loginInfo.pwdChangeFlag) {
          store.state.login.mainComponentPath = loginInfo.mainComponentPath
          store.state.login.componentTitle = loginInfo.componentTitle
          store.state.login.userSeq = loginInfo.userSeq
          store.state.login.spotSeq = loginInfo.spotSeq
          store.state.login.spotName = loginInfo.spotName
          store.state.login.userId = loginInfo.userId
          store.state.login.userName = loginInfo.userName
          store.state.login.loginKey = loginInfo.loginKey
          store.state.login.encKey = loginInfo.encKey
          store.state.login.menuList = loginInfo.menuList
          store.state.login.consignCompName = loginInfo.consignCompName
          store.state.login.userGroupCode = loginInfo.userGroupCode
          store.state.login.userGroupName = loginInfo.userGroupName
          store.state.login.pwdChangeFlag = loginInfo.pwdChangeFlag
          store.state.login.userTypeGroup = loginInfo.userTypeGroup
          store.state.login.menuListGroup = loginInfo.menuListGroup

          let flag = false
          // console.log('currentPath : ', currentPath)
          _.map(loginInfo.menuList, function (menu1) {
            _.map(menu1.items, function (menu2) {
              if (menu2.items === undefined || menu2.items === null) { // depth가 2인 경우
                if (menu2.menuPath.indexOf(currentPath) >= 0) {
                  // console.log('check1')
                  flag = true
                  // groupPermission의 경우 0 - 권한없음  // 1 -  읽기 //  2 - 읽기/쓰기
                  const updateFlag = menu2.groupPermission === 2
                  store.state.currentMenuUpdateFlag = updateFlag
                  return null
                }
              } else {
                _.map(menu2.items, function (menu3) { // 메뉴 depth가 3인 경우
                  if (menu3.menuPath.indexOf(currentPath) >= 0) {
                    // console.log('check2')
                    flag = true
                    const menuName = menu3.menuName
                    const updateFlag = menu3.groupPermission === 2
                    store.state.currentMenuTitle = menuName
                    store.state.currentMenuUpdateFlag = updateFlag
                    return null
                  }
                })
              }
              if (flag) {
                return null
              }
            })
            if (flag) {
              return null
            }
          })
          return next()
        } else {
          store.state.login.userSeq = null
          store.state.login.spotSeq = null
          store.state.login.spotName = ''
          store.state.login.userId = ''
          store.state.login.userName = ''
          store.state.login.consignCompName = ''
          store.state.login.loginKey = ''
          store.state.login.encKey = ''
          store.state.login.menuList = []
          store.state.login.userGroupCode = ''
          store.state.login.userGroupName = ''
          store.state.login.pwdChangeFlag = ''
          // store.state.login.componentGubun = ''
          store.state.login.componentTitle = ''
          store.state.login.mainComponentPath = ''
          store.state.currentMenuUpdateFlag = false
          next('/loginForm')
        }
      }
    } else {
      store.state.login.userSeq = null
      store.state.login.spotSeq = null
      store.state.login.spotName = ''
      store.state.login.userId = ''
      store.state.login.userName = ''
      store.state.login.consignCompName = ''
      store.state.login.loginKey = ''
      store.state.login.encKey = ''
      store.state.login.menuList = []
      store.state.login.userGroupCode = ''
      store.state.login.userGroupName = ''
      store.state.login.pwdChangeFlag = ''
      // store.state.login.componentGubun = ''
      store.state.login.componentTitle = ''
      store.state.login.mainComponentPath = ''
      store.state.currentMenuUpdateFlag = false
      next('/loginForm')
    }
  }, 10)
}
// 로그인세션확인
function loginView () {
  const token = sessionStorage.getItem('botw_access_info')
  // console.log('accessInfo1', token)
  if (token !== undefined && token !== null && token !== '') {
    store.state.login.userSeq = null
    store.state.login.spotSeq = null
    store.state.login.spotName = ''
    store.state.login.userId = ''
    store.state.login.userName = ''
    store.state.login.consignCompName = ''
    store.state.login.loginKey = ''
    store.state.login.encKey = ''
    store.state.login.menuList = []
    store.state.login.userGroupCode = ''
    store.state.login.userGroupName = ''
    store.state.login.pwdChangeFlag = ''
    // store.state.login.componentGubun = ''
    store.state.login.componentTitle = ''
    store.state.login.mainComponentPath = ''
    store.state.currentMenuUpdateFlag = false
    return () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue')
  } else {
    store.state.login.userSeq = null
    store.state.login.spotSeq = null
    store.state.login.spotName = ''
    store.state.login.userId = ''
    store.state.login.userName = ''
    store.state.login.consignCompName = ''
    store.state.login.loginKey = ''
    store.state.login.encKey = ''
    store.state.login.menuList = []
    store.state.login.userGroupCode = ''
    store.state.login.userGroupName = ''
    store.state.login.pwdChangeFlag = ''
    // store.state.login.componentGubun = ''
    store.state.login.componentTitle = ''
    store.state.login.mainComponentPath = ''
    store.state.currentMenuUpdateFlag = false
    return Login
  }
}

export default new Router({
  // mode: 'history', // NH: hash모드 / 2.6버전: history모드
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: [
    { path: '/loginForm', name: 'loginForm', component: loginView() },
    // { path: '/', name: 'ssoLoginForm', component: loginView(), beforeEnter: ssoCheck() },
    {
      path: '/botw/sub',
      name: 'Layout',
      component: () => import(/* webpackChunkName: "Layout" */ '@/views/sub/Layout.vue'),
      beforeEnter: requireAuth(''),
      children: [
        // { path: '/botw/hn/contract/', name: `homeNetContract${store.state.login.componentTitle}`, component: () => import(/* webpackChunkName: "homeNetCheck" */ `@/views/sub/homeNet/contract/${store.state.login.componentTitle}List.vue`), beforeEnter: requireAuth('homeNetContract') },
        // { path: '/botw/hn/check/', name: `homeNetCheck${store.state.login.componentTitle}`, component: () => import(/* webpackChunkName: "homeNetCheck" */ `@/views/sub/homeNet/check/${store.state.login.componentTitle}List.vue`), beforeEnter: requireAuth('homeNetCheck') },
        { path: '/botw/hn/contract/', name: 'homeNetContractSpot', component: () => import(/* webpackChunkName: "homeNetCheck" */ '@/views/sub/homeNet/contract/SpotList.vue'), beforeEnter: requireAuth('homeNetSpot') },
        { path: '/botw/hn/contract/', name: 'homeNetContractRemote', component: () => import(/* webpackChunkName: "homeNetCheck" */ '@/views/sub/homeNet/contract/RemoteList.vue'), beforeEnter: requireAuth('homeNetContract') },
        { path: '/botw/hn/contract/', name: 'homeNetContractContract', component: () => import(/* webpackChunkName: "homeNetCheck" */ '@/views/sub/homeNet/contract/ContractList.vue'), beforeEnter: requireAuth('homeNetContract') },
        { path: '/botw/hn/check/', name: 'homeNetCheckRemote', component: () => import(/* webpackChunkName: "homeNetCheck" */ '@/views/sub/homeNet/check/RemoteList.vue'), beforeEnter: requireAuth('homeNetCheck') },
        { path: '/botw/hn/check/', name: 'homeNetCheckSpot', component: () => import(/* webpackChunkName: "homeNetCheck" */ '@/views/sub/homeNet/check/SpotList.vue'), beforeEnter: requireAuth('homeNetCheck') },
        { path: '/botw/hn/check/', name: 'homeNetCheckContract', component: () => import(/* webpackChunkName: "homeNetCheck" */ '@/views/sub/homeNet/check/ContractList.vue'), beforeEnter: requireAuth('homeNetCheck') },

        /* 정보통신-사용자 시작(TBD)_임시 fe 메인화면부터 정해진 내용이 없는 바 BE 도 정해진 내용 없음, 즉, 임시 '/botw/main'명 */
        { path: '/botw/main', name: `${store.state.login.componentTitle}Main`, component: () => import(/* webpackChunkName: "Main" */ `@/views/sub/main/${store.state.login.mainComponentPath}Main.vue`), beforeEnter: requireAuth('Main') },
        /* 홈네트워크-메인 */
        { path: '/botw/main/homeNet/spot', name: `${store.state.login.componentTitle}Main`, component: () => import(/* webpackChunkName: "Main" */ '@/views/sub/main/homeNet/spot/Main.vue'), beforeEnter: requireAuth('Main') },
        { path: '/botw/main/homeNet/remote', name: `${store.state.login.componentTitle}Main`, component: () => import(/* webpackChunkName: "Main" */ '@/views/sub/main/homeNet/remote/Main.vue'), beforeEnter: requireAuth('Main') },
        { path: '/botw/main/homeNet/contract', name: `${store.state.login.componentTitle}Main`, component: () => import(/* webpackChunkName: "Main" */ '@/views/sub/main/homeNet/contract/Main.vue'), beforeEnter: requireAuth('Main') },
        { path: '/botw/main/homeNet', name: `${store.state.login.componentTitle}Main`, component: () => import(/* webpackChunkName: "Main" */ '@/views/sub/main/system/Main.vue'), beforeEnter: requireAuth('Main') },

        // 현장정보
        { path: '/botw/spotInfo/checkFacility/', name: 'SpotInfoCheckFacilityList', component: () => import(/* webpackChunkName: "SpotInfoCheckFacilityList" */ '@/views/sub/icfacilities/spotInfo/checkFacility/List.vue'), beforeEnter: requireAuth('SpotInfoCheckFacilityList') },
        { path: '/botw/spotInfo/contract/', name: 'SpotInfoContractList', component: () => import(/* webpackChunkName: "SpotInfoContractList" */ '@/views/sub/icfacilities/spotInfo/contract/List.vue'), beforeEnter: requireAuth('SpotInfoContractList') },

        // 유지보수점검
        // // 점검실시
        { path: '/botw/maintain/check/', name: 'MaintainCheckList', component: () => import(/* webpackChunkName: "MaintainCheckList" */ '@/views/sub/icfacilities/maintain/check/List.vue'), beforeEnter: requireAuth('MaintainCheckList') },
        // // 점검이력
        { path: '/botw/maintain/history/', name: 'MaintainHistoryList', component: () => import(/* webpackChunkName: "MaintainHistoryList" */ '@/views/sub/icfacilities/maintain/history/List.vue'), beforeEnter: requireAuth('MaintainHistoryList') },

        // 성능점검
        // // 점검실시
        { path: '/botw/perform/check/', name: 'PerformCheckCommList', component: () => import(/* webpackChunkName: "PerformCheckCommList" */ '@/views/sub/icfacilities/perform/check/List.vue'), beforeEnter: requireAuth('PerformCheckList') },
        // // 점검이력
        { path: '/botw/perform/history/', name: 'PerformHistoryList', component: () => import(/* webpackChunkName: "PerformHistoryList" */ '@/views/sub/icfacilities/perform/history/List.vue'), beforeEnter: requireAuth('PerformHistoryList') },

        // 설비고장
        { path: '/botw/err/process/', name: 'ErrProcessList', component: () => import(/* webpackChunkName: "ErrProcessList" */ '@/views/sub/icfacilities/err/process/List.vue'), beforeEnter: requireAuth('ErrProcessList') },
        // { path: '/botw/err/history/', name: 'ErrHistoryList', component: () => import(/* webpackChunkName: "ErrHistoryList" */ '@/views/sub/err/history/List.vue'), beforeEnter: requireAuth('ErrHistoryList') },

        /* 설정 시작 */
        // 현장설비관리
        { path: '/botw/config/spotFacility/', name: 'ConfigSpotFacilityList', component: () => import(/* webpackChunkName: "ConfigSpotFacilityList" */ '@/views/sub/config/icfacilities/spotFacility/List.vue'), beforeEnter: requireAuth('ConfigSpotFacilityList') },

        // 현장별 점검항목관리
        { path: '/botw/config/spotCheck/', name: 'ConfigSpotCheckList', component: () => import(/* webpackChunkName: "ConfigSpotCheckList" */ '@/views/sub/config/icfacilities/spotCheck/List.vue'), beforeEnter: requireAuth('ConfigSpotCheckList') },
        // { path: '/botw/config/facility/', name: 'ConfigFacilityList', component: () => import(/* webpackChunkName: "ConfigFacilityList" */ '@/views/sub/config/facility/List.vue'), beforeEnter: requireAuth('ConfigFacilityList') },
        // 사용자관리
        { path: '/botw/config/user/', name: 'ConfigUserList', component: () => import(/* webpackChunkName: "ConfigUserList" */ '@/views/sub/config/admin/user/List.vue'), beforeEnter: requireAuth('ConfigUserList') },
        // 설정 - 홈네트워크
        // 계약업체등록
        { path: '/botw/config/contractComp/', name: 'ConfigContractComp', component: () => import(/* webpackChunkName: "ConfigContractComp" */ '@/views/sub/config/homeNet/contractComp/List.vue'), beforeEnter: requireAuth('ConfigContractComp') },
        // 점검업체등록
        { path: '/botw/config/visitComp/', name: 'ConfigVisitComp', component: () => import(/* webpackChunkName: "ConfigVisitComp" */ '@/views/sub/config/homeNet/visitComp/List.vue'), beforeEnter: requireAuth('ConfigVisitComp') },
        // 계약관리
        { path: '/botw/config/hnContract/', name: 'ConfigHnContract', component: () => import(/* webpackChunkName: "ConfigHnContract" */ '@/views/sub/config/homeNet/contract/List.vue'), beforeEnter: requireAuth('ConfigHnContract') },

        // 설정 - 관리자
        { path: '/botw/config/contract/', name: 'ConfigContractList', component: () => import(/* webpackChunkName: "ConfigContractList" */ '@/views/sub/config/icfacilities/contract/List.vue'), beforeEnter: requireAuth('ConfigContractList') },
        { path: '/botw/config/check/', name: 'ConfigCheckList', component: () => import(/* webpackChunkName: "ConfigCheckList" */ '@/views/sub/config/icfacilities/check/List.vue'), beforeEnter: requireAuth('ConfigCheckList') },
        { path: '/botw/config/performCheck/', name: 'ConfigSpotPerformCheckList', component: () => import(/* webpackChunkName: "ConfigSpotPerformCheckList" */ '@/views/sub/config/icfacilities/performCheck/List.vue'), beforeEnter: requireAuth('ConfigSpotPerformCheckList') },
        { path: '/botw/config/spot/', name: 'ConfigSpotList', component: () => import(/* webpackChunkName: "ConfigSpotList" */ '@/views/sub/config/admin/spot/List.vue'), beforeEnter: requireAuth('ConfigSpotList') },
        { path: '/botw/config/consignComp/', name: 'ConfigConsignCompList', component: () => import(/* webpackChunkName: "ConfigConsignCompList" */ '@/views/sub/config/admin/consignComp/List.vue'), beforeEnter: requireAuth('ConfigConsignCompList') },

        // 설정 - 시스템
        { path: '/botw/config/codeGroup/', name: 'ConfigCodeGroupList', component: () => import(/* webpackChunkName: "ConfigCodeGroupList" */ '@/views/sub/config/system/codeGroup/List.vue'), beforeEnter: requireAuth('ConfigCodeGroupList') },
        { path: '/botw/config/code/', name: 'ConfigCodeList', component: () => import(/* webpackChunkName: "ConfigCodeList" */ '@/views/sub/config/system/code/List.vue'), beforeEnter: requireAuth('ConfigCodeList') },
        { path: '/botw/config/menu/', name: 'ConfigMenuList', component: () => import(/* webpackChunkName: "ConfigMenuList" */ '@/views/sub/config/system/menu/List.vue'), beforeEnter: requireAuth('ConfigMenuList') },
        { path: '/botw/config/userGroup/', name: 'ConfigUserGroupList', component: () => import(/* webpackChunkName: "ConfigUserGroupList" */ '@/views/sub/config/system/userGroup/List.vue'), beforeEnter: requireAuth('ConfigUserGroupList') },
        { path: '/botw/config/userGroupPermission/', name: 'ConfigUserGroupPermissionList', component: () => import(/* webpackChunkName: "ConfigUserGroupPermissionList" */ '@/views/sub/config/system/userGroupPermission/List.vue'), beforeEnter: requireAuth('ConfigUserGroupPermissionList') },
        /* 설정 끝 */

        /* error : 403 */
        { path: '/botw/error/403', name: 'Error403', component: () => import(/* webpackChunkName: "Error403" */ '@/views/sub/403.vue') }
      ]
    },

    { path: '*', redirect: '/loginForm' }
  ]
})
